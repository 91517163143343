<template>
  <div class="toggle-class sidebar-width">
    <div class="d-flex column justify-content-between logo-toggle">
      <img role="button" src="../../assets/logo.png" class="primary-color pe-2" alt="Logo">
      <img role="button" src="../../assets/sidebar/close-sidebar.svg" class="primary-color ps-2 p-2" alt="Toggle" @click="closeSidebar()">
    </div>
    <div class="primary-color p-2 d-flex align-items-center justify-content-center">

    </div>
    <div class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path === '/' || $route.path === 'dashboard'">
      <a href="/" @click.prevent="goToPath('home')" class="d-flex align-items-center text-decoration-none w-100 h-100">
        <img class="me-2 mh-100" src="../../assets/sidebar/dashboard-active.svg" alt="Dashboard">
        <span class="primary-color">Dashboard</span>
      </a>
    </div>

    <div role="button" class="gray-color p-2 mb-2 border-left-custom" v-else @click="goToPath('home')">
      <a href="/" @click.prevent="goToPath('home')" class="d-flex align-items-center text-decoration-none w-100 h-100">
        <img class="me-2 mh-100" src="../../assets/sidebar/dashboard.svg" alt="Dashboard">
        <span class="color-gray-707070">Dashboard</span>
      </a>
    </div>
    <!-- START ADMIN MENUS-->
    <div v-if="storeAuth.userPermissionsGetters.users && storeAuth.userPermissionsGetters.users.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,6) === '/users'" @click="goToPath('users')">
        <a href="/users" @click.prevent="goToPath('users')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/users-active.svg" alt="Personale">
          <span class="primary-color">Personale</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('users')">
        <a href="/users" @click.prevent="goToPath('users')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/users.svg" alt="Personale">
          <span class="color-gray-707070">Personale</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.employees && storeAuth.userPermissionsGetters.employees.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,10) === '/employees'" @click="goToPath('employees')">
        <a href="/employees" @click.prevent="goToPath('employees')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/users-active.svg" alt="Operatori">
          <span class="primary-color">Operatori</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('employees')">
        <a href="/employees" @click.prevent="goToPath('employees')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/users.svg" alt="Operatori">
          <span class="color-gray-707070">Operatori</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.clients && storeAuth.userPermissionsGetters.clients.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,8) === '/clients'" @click="goToPath('clients')">
        <a href="/clients" @click.prevent="goToPath('clients')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/client-active.svg" alt="Clienti">
          <span class="primary-color">Utenti</span>
        </a>
      </div>

      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('clients')">
        <a href="/clients" @click.prevent="goToPath('clients')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/client.svg" alt="Clienti">
          <span class="color-gray-707070">Utenti</span>
        </a>
      </div>
    </div>

    <div v-if="storeAuth.userPermissionsGetters.default_weeks && storeAuth.userPermissionsGetters.default_weeks.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,13) === '/default-week'" @click="goToPath('defaultWeek')">
        <a href="/default-week" @click.prevent="goToPath('defaultWeek')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/week-type-active.svg" alt="Settimana Tipo">
          <span class="primary-color">Settimana Tipo</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('defaultWeek')">
        <a href="/default-week" @click.prevent="goToPath('defaultWeek')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/week-type.svg" alt="Settimana Tipo">
          <span class="color-gray-707070">Settimana Tipo</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.permissions && storeAuth.userPermissionsGetters.permissions.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,12) === '/permissions'" @click="goToPath('permissions')">
        <a href="/permissions" @click.prevent="goToPath('permissions')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/permissions-active.svg" alt="Permessi">
          <span class="primary-color">Permessi</span>
        </a>
      </div>

      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('permissions')">
        <a href="/permissions" @click.prevent="goToPath('permissions')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/permissions.svg" alt="Permessi">
          <span class="color-gray-707070">Permessi</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.shifts && storeAuth.userPermissionsGetters.shifts.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,17) === '/uncovered-shifts'" @click="goToPath('uncoveredShifts')">
        <a href="/uncovered-shifts" @click.prevent="goToPath('uncoveredShifts')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/substitution-active.svg" alt="Permessi">
          <span class="primary-color">Scoperto</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('uncoveredShifts')">
        <a href="/uncovered-shifts" @click.prevent="goToPath('uncoveredShifts')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/substitution.svg" alt="Permessi">
          <span class="color-gray-707070">Scoperto</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.shifts && storeAuth.userPermissionsGetters.shifts.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,7) === '/shifts'" @click="goToPath('shifts')">
        <a href="/shifts" @click.prevent="goToPath('shifts')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/shifts-active.svg" alt="Turni">
          <span class="primary-color">Turni</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('shifts')">
        <a href="/shifts" @click.prevent="goToPath('shifts')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/shifts.svg" alt="Turni">
          <span class="color-gray-707070">Turni</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.services && storeAuth.userPermissionsGetters.services.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,9) === '/services'" @click="goToPath('services')">
        <a href="/services" @click.prevent="goToPath('services')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/services-active.svg" alt="Servizi">
          <span class="primary-color">Servizi</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('services')">
        <a href="/services" @click.prevent="goToPath('services')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/services.svg" alt="Servizi">
          <span class="color-gray-707070">Servizi</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.electronic_badges && storeAuth.userPermissionsGetters.electronic_badges.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,17) === '/electronic-badge'" @click="goToPath('electronicBadge')">
        <a href="/electronic-badge" @click.prevent="goToPath('electronicBadge')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/electronic-badge-active.svg" alt="Badge Elettronico">
          <span class="primary-color">Badge Elettronico</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('electronicBadge')">
        <a href="/electronic-badge" @click.prevent="goToPath('electronicBadge')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/electronic-badge.svg" alt="Badge Elettronico">
          <span class="color-gray-707070">Badge Elettronico</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.holidays && storeAuth.userPermissionsGetters.holidays.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,9) === '/holidays'" @click="goToPath('holidays')">

        <a href="/holidays" @click.prevent="goToPath('holidays')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/calendar-holidays-active.svg" alt="Festivi">
          <span class="primary-color">Festivi</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('holidays')">
        <a href="/holidays" @click.prevent="goToPath('holidays')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/calendar-holidays.svg" alt="Festivi">
          <span class="color-gray-707070">Festivi</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.statistics && storeAuth.userPermissionsGetters.statistics.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,11) === '/statistics'" @click="goToPath('statistics')">

        <a href="/statistics" @click.prevent="goToPath('statistics')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/statistics-active.svg" alt="Statistiche">
          <span class="primary-color">Statistiche</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('statistics')">
        <a href="/statistics" @click.prevent="goToPath('statistics')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/statistics.svg" alt="Statistiche">
          <span class="color-gray-707070">Statistiche</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.settings && storeAuth.userPermissionsGetters.settings.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,9) === '/settings'" @click="goToPath('settings')">
        <a href="/settings" @click.prevent="goToPath('settings')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/settings-active.svg" alt="Impostazioni">
          <span class="primary-color">Impostazioni</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('settings')">
        <a href="/settings" @click.prevent="goToPath('settings')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/settings.svg" alt="Impostazioni">
          <span class="color-gray-707070">Impostazioni</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.transactions && storeAuth.userPermissionsGetters.transactions.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,12) === '/transaction'" @click="goToPath('transactions')">
        <a href="/transaction" @click.prevent="goToPath('transactions')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/transactions_active.svg" alt="Transazioni">
          <span class="primary-color">Transazioni</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('transactions')">
        <a href="/transaction" @click.prevent="goToPath('transactions')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/transactions.svg" alt="Transazioni">
          <span class="color-gray-707070">Transazioni</span>
        </a>
      </div>
    </div>

    <!--  END ADMIN MENUS-->

    <!--  START WORKERS MENUS-->
    <div v-if="storeAuth.userPermissionsGetters.employee_calendar && storeAuth.userPermissionsGetters.employee_calendar.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,18) === '/employee-calendar'" @click="goToPath('employeeCalendar')">
        <a href="/employee-calendar" @click.prevent="goToPath('employeeCalendar')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/calendar-active.svg" alt="Calendario">
          <span class="primary-color">Calendario</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('employeeCalendar')">
        <a href="/employee-calendar" @click.prevent="goToPath('employeeCalendar')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/calendar.svg" alt="Calendario">
          <span class="color-gray-707070">Calendario</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.electronic_badge_employee && storeAuth.userPermissionsGetters.electronic_badge_employee.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,26) === '/electronic-badge-employee'" @click="goToPath('electronicBadgeEmployee')">
        <a href="/electronic-badge-employee" @click.prevent="goToPath('electronicBadgeEmployee')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/electronic-badge-active.svg" alt="Badge Elettronico">
          <span class="primary-color">Badge Elettronico</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('electronicBadgeEmployee')">
        <a href="/electronic-badge-employee" @click.prevent="goToPath('electronicBadgeEmployee')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/electronic-badge.svg" alt="Badge Elettronico">
          <span class="color-gray-707070">Badge Elettronico</span>
        </a>
      </div>
    </div>
    <div v-if="storeAuth.userPermissionsGetters.total_hours && storeAuth.userPermissionsGetters.total_hours.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,12) === '/total-hours'" @click="goToPath('totalHours')">
        <a href="/total-hours" @click.prevent="goToPath('totalHours')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/total-hours-active.svg" alt="Monte Ore">
          <span class="primary-color">Monte Ore</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('totalHours')">
        <a href="/total-hours" @click.prevent="goToPath('totalHours')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/total-hours.svg" alt="Monte Ore">
          <span class="color-gray-707070">Monte Ore</span>
        </a>
      </div>
    </div>

    <div v-if="storeAuth.userPermissionsGetters.employee_permission && storeAuth.userPermissionsGetters.employee_permission.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,20) === '/employee-permission'" @click="goToPath('employeePermissions')">
        <a href="/employee-permission" @click.prevent="goToPath('employeePermissions')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/permissions-active.svg" alt="Permessi">
          <span class="primary-color">Permessi</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('employeePermissions')">
        <a href="/employee-permission" @click.prevent="goToPath('employeePermissions')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/permissions.svg" alt="Permessi">
          <span class="color-gray-707070">Permessi</span>
        </a>
      </div>
    </div>

    <!--    <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path === '/substitutions'">-->
    <!--      <img class="me-2 mh-100" src="../../assets/sidebar/substitution-active.svg" alt="Sostituzioni">-->
    <!--      <span class="primary-color">Sostituzioni</span>-->
    <!--    </div>-->
    <!--    <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else>-->
    <!--      <img class="me-2 mh-100" src="../../assets/sidebar/substitution.svg" alt="Sostituzioni">-->
    <!--      <span class="">Sostituzioni</span>-->
    <!--    </div>-->

    <div v-if="storeAuth.userPermissionsGetters.profile && storeAuth.userPermissionsGetters.profile.includes('view')">
      <div role="button" class="primary-color p-2 sidebar-border-left mb-2" v-if="$route.path.substring(0,8) === '/profile'" @click="goToPath('profile')">
        <a href="/profile" @click.prevent="goToPath('profile')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/profile-active.svg" alt="Profilo">
          <span class="primary-color">Profilo</span>
        </a>
      </div>
      <div role="button" class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" v-else @click="goToPath('profile')">
        <a href="/profile" @click.prevent="goToPath('profile')" class="d-flex align-items-center text-decoration-none w-100 h-100">
          <img class="me-2 mh-100" src="../../assets/sidebar/profile.svg" alt="Profilo">
          <span class="color-gray-707070">Profilo</span>
        </a>
      </div>
    </div>

    <div class="gray-color p-2 d-flex align-items-center mb-2 border-left-custom" role="button" @click="logout">
      <img class="me-2 mh-100" src="../../assets/sidebar/logout.svg" alt="Esci">
      <span class="">Esci</span>
    </div>


    <!--  END WORKERS MENUS-->
  </div>
</template>

<script>

import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";
import {useUserStore} from "@/stores/users";
import {useEmployeeStore} from "@/stores/employees";
import {useClientStore} from "@/stores/clients";
import {useServiceStore} from "@/stores/services";
import {useDefaultWeekStore} from "@/stores/defaultWeeks";
import {useElectronicBadgeStore} from "@/stores/electronicBadge";
import {usePermissionStore} from "@/stores/permissions";
import {useShiftStore} from "@/stores/shifts";

export default {
  name: "ComponentSideBar",
  setup() {
    const storeApi = useApiSettingStore()
    const storeAuth = useAuthStore()
    return {
      storeApi,
      storeAuth
    }
  },
  data() {
    return {
      boolSidebar: false,
      switchChecked: null,
      hiddenContractSubmenu: true
    }
  },
  created() {
  },
  methods: {
    logout() {
      useApiSettingStore().typePopup = 'logout'
      useApiSettingStore().overlayPopup = true
    },

    goToPath(name) {
      useUserStore().setQueryParams()
      useEmployeeStore().setQueryParams()
      useClientStore().setQueryParams()
      useServiceStore().setQueryParams();
      useDefaultWeekStore().setQueryParams()
      useElectronicBadgeStore().setQueryParams()
      usePermissionStore().setQueryParams()
      useShiftStore().setQueryParamsUncover()
      useShiftStore().setQueryParams()
      this.$router.push({name: name}).catch(() => {
      })
    },

    closeSidebar() {
      let sidebar = document.getElementById('sidebar-app')
      if (sidebar) {
        if (window.matchMedia("(orientation: portrait)").matches) {
          sidebar.style.width = '0px'
          sidebar.style.padding = '0px'
        } else {
          let toggle_open = document.getElementsByClassName('toggle-open')[0]
          toggle_open.style.display = 'block'
          let sidebar = document.getElementsByClassName('toggle-class')[0]
          sidebar.style.display = 'none'
        }
      }
    }
  },
  beforeUnmount() {
    window.addEventListener('resize', function () {
    })
  },
  unmounted() {
    window.addEventListener('resize', function () {
    })
  }
}
</script>

<style scoped>

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.fill-logo-img path {
  fill: #000;
}

.border-left-custom {
  border-left: 4px solid transparent !important;
}

.logo-toggle {
  padding: 10px;
  height: 60px;
  background: #FAFAFA;
  position: sticky;
  top: 0;
  z-index: 1000;
  left: 0;
}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
  .container-single-link {
    padding: 10px 10px 10px 15px;
  }
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {

}

</style>