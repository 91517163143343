import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {isNotIntegerOrNull} from "@/main";
import moment from "moment";

const currentDate = new Date()
const startRange = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
const endRange = new Date(newDate);
endRange.setDate(newDate.getDate() - 1);
export const useEmployeeStore = defineStore("employee", {
    state: () => ({
        employees: null,
        employee: null,
        spinnerEmployee: false,
        employeeTypes: null,
        startRange: null,
        endRange: null,
        paginationEmployee: Number(process.env['VUE_APP_PER_PAGE']),
        queryParams: {
            availability: '',
            start_range: moment(startRange).format('YYYY-MM-DD'),
            end_range: moment(endRange).format('YYYY-MM-DD'),
            availability_date: moment(currentDate).format('YYYY-MM-DD'),
            start_time: '',
            end_time: '',
            search: '',
            page: 1
        },
    }),
    actions: {
        setStartRange(start) {
            this.startRange = start
        },
        setEndRange(end) {
            this.endRange = end
        },

        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                const currentDate = new Date()
                const startRange = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
                const endRange = new Date(newDate);
                endRange.setDate(newDate.getDate() - 1);
                this.queryParams = {
                    availability: '',
                    start_range: moment(startRange).format('YYYY-MM-DD'),
                    end_range: moment(endRange).format('YYYY-MM-DD'),
                    availability_date: moment(currentDate).format('YYYY-MM-DD'),
                    search: '',
                    start_time: '',
                    end_time: '',
                    page: 1
                }
            }
        },


        setEmployeePagination(employeePagination) {
            this.paginationEmployee = employeePagination
        },
        getEmployee(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/employees/' + id + '/', config)
                    .then(res => {
                        this.employee = res.data
                        resolve(res)
                        this.spinnerEmployee = false
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            this.spinnerEmployee = false
                        }
                        reject(err)
                    })
            })
        },
        getEmployees(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationEmployee
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/employees/')

                this.spinnerEmployee = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.employees = res.data
                            this.spinnerEmployee = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerEmployee = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerEmployee = false
                            this.employees = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerEmployee = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

        getBaseEmployees(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationEmployee
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/employees-search/')

                this.spinnerEmployee = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.spinnerEmployee = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerEmployee = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.employees = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerEmployee = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

        getAvailableEmployees(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (!this.startRange && !this.endRange) {
                payload.queryParams.start_range = this.startRange
                payload.queryParams.end_range = this.endRange
            }
            payload.queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/employees/available/')

                this.spinnerEmployee = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.post(url.pathname + url.search, payload.data, config)
                        .then(res => {
                            this.employees = res.data
                            this.spinnerEmployee = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerEmployee = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.post(url.pathname, payload.data, config)
                        .then(res => {
                            this.spinnerEmployee = false
                            this.employees = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerEmployee = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

        getAllEmployees() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                let url = new URL(axios.defaults.baseURL + 'api/employees/list-all/')
                this.spinnerEmployee = true
                axios.get(url.pathname, config)
                    .then(res => {
                        this.spinnerEmployee = false
                        resolve(res)
                    })
                    .catch(err => {
                        this.spinnerEmployee = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        editEmployee(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/employees/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        requestEdit(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/request-change/' + payload.id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })

        },

        rejectOrApproveEdit(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/approve-reject-request', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })

        },

        deleteEmployee(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/employees/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        getTotalHours() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/get-total-hours/', config)
                    .then(res => {
                        resolve(res)
                        this.spinnerEmployee = false
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            this.spinnerEmployee = false
                        }
                        reject(err)
                    })
            })
        },
    },
    getters: {
        employeesGetters: state => {
            return state.employees
        },
        employeeGetters: state => {
            return state.employee
        },
        spinnerEmployeeGetters: state => {
            return state.spinnerEmployee
        },
        paginationEmployeeGetters: state => {
            return state.paginationEmployee
        },
        employeeTypesGetters: state => {
            return state.employeeTypes
        }
    }
})