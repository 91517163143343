import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {Client} from "@/models/client.js";
import {isNotIntegerOrNull} from "@/main";
import {format} from "date-fns";

export const useClientStore = defineStore("clients", {
    state: () => ({
        clients: null,
        client: Client,
        spinnerClient: false,
        openMap: false,
        queryParams: {search: '', page: 1, date: format(new Date(), 'yyyy-MM-dd')},
        paginationClient: Number(process.env['VUE_APP_PER_PAGE']),
    }),
    actions: {
        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                this.queryParams = {
                    search: '',
                    date: format(new Date(), 'yyyy-MM-dd'),
                    page: 1
                }
            }
        },

        toggleOpenMap(openMap = null) {
            this.openMap = openMap === null ? !this.openMap : openMap
        },

        setClientPagination(clientPagination) {
            this.paginationClient = clientPagination
        },

        getClient(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerClient = true
                axios.get('api/clients/' + id + '/', config)
                    .then(res => {
                        this.client = res.data
                        resolve(res)
                        this.spinnerClient = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                    })
            })
        },

        getClients(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationClient
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                let url = new URL(axios.defaults.baseURL + 'api/clients/')

                this.spinnerClient = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.clients = res.data
                            this.spinnerClient = false
                            resolve(res)
                        }, reason => {
                            this.spinnerClient = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, reason.response.data)
                        })
                        .catch(err => {
                            this.spinnerClient = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerClient = false
                            this.clients = res.data
                            resolve(res)
                        }, reason => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, reason.response.data)
                        })
                        .catch(err => {
                            this.spinnerClient = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            reject(err)
                        })
                }

            })
        },

        editClient(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/clients/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, reason.response.data)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                    })
            })
        },
        addClient(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/clients/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })

        },

        deleteClient(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/clients/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        deactivateClient(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerClient = true
                axios.get('api/clients/' + payload + '/deactivate/', config)
                    .then(res => {
                        this.spinnerClient = false
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().overlayPopup = true
                            useApiSettingStore().typePopup = 'success'
                            setTimeout(() => {
                                useApiSettingStore().overlayPopup = false
                                useApiSettingStore().typePopup = ''
                                useApiSettingStore().popUpMessage = null
                            }, 1000)
                        }
                    }, reason => {
                        resolve(reason)
                        this.spinnerClient = false
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        this.spinnerClient = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        activateClient(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerClient = true

                axios.get('api/clients/' + payload + '/activate/', config)
                    .then(res => {
                        this.spinnerClient = false
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        this.spinnerClient = false
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        this.spinnerClient = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        clientsGetters: state => {
            return state.clients
        },
        clientGetters: state => {
            return state.client
        },
        spinnerClientGetters: state => {
            return state.spinnerClient
        },
        paginationClientGetters: state => {
            return state.paginationClient
        }
    }
})