import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useNotificationStore} from "@/stores/notifications";
import {useElectronicBadgeStore} from "@/stores/electronicBadge";

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {
            hideForAuth: true
        },
        component: () => import('../views/LoginView')
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        meta: {
            hideForAuth: true
        },
        component: () => import('../views/ResetPassword')
    },
    {
        path: '/',
        name: 'home',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/HomeView')
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ProfileView')
    },

    {
        path: '/users',
        name: 'users',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/UsersView')
    },

    {
        path: '/users/:id',
        name: 'detailUser',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/DetailUser')
    },

    {
        path: '/users/add',
        name: 'addUser',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/AddUser')
    },

    {
        path: '/employees',
        name: 'employees',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/EmployeesView')
    },

    {
        path: '/employees/:id',
        name: 'detailEmployee',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/DetailEmployee')
    },

    {
        path: '/employees/add',
        name: 'addEmployee',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/AddEmployee')
    },


    {
        path: '/services/:search?',
        name: 'services',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ServicesView')
    },
    {
        path: '/clients/:search?',
        name: 'clients',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ClientsView')
    },

    {
        path: '/shifts',
        name: 'shifts',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ShiftsView')
    },
    {
        path: '/shifts/:id',
        name: 'shiftAddEdit',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ShiftAddEditView')
    },

    {
        path: '/shifts/bulk-update/:ids',
        name: 'shiftBulkEdit',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ShiftBulkEditView')
    },
    {
        path: '/uncovered-shifts',
        name: 'uncoveredShifts',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/UncoveredShifts')
    },

    {
        path: '/electronic-badge',
        name: 'electronicBadge',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ElectronicBadgeView')
    },
    {
        path: '/electronic-badge-employee',
        name: 'electronicBadgeEmployee',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/ElectronicBadgeEmployeeView')
    },
    {
        path: '/employee-electronic-badge-calendar/:id',
        name: 'employeeElectronicBadgeCalendar',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/EmployeeElectronicBadgeCalendarView')
    },
    {
        path: '/default-week',
        name: 'defaultWeek',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/DefaultWeekView')
    },
    {
        path: '/default-week-add',
        name: 'addDefaultWeek',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/AddDefaultWeekView')
    },
    {
        path: '/default-week/:id',
        name: 'detailDefaultWeek',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/DetailDefaultWeekView')
    },
    {
        path: '/default-week-add-shift/:id',
        name: 'addShiftFromDefaultWeekView',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/AddShiftFromDefaultWeekView')
    },

    {
        path: '/permissions',
        name: 'permissions',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/PermissionsView')
    },

    {
        path: '/employee-permission/:id',
        name: 'employeePermission',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/EmployeePermissionView')
    },

    {
        path: '/employee-permissions',
        name: 'employeePermissions',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/EmployeePermissionsView')
    },

    {
        path: '/notifications',
        name: 'notifications',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/NotificationView')
    },
    {
        path: '/employee-calendar',
        name: 'employeeCalendar',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/EmployeeCalendarView')
    },
    {
        path: '/calendar/:id',
        name: 'calendar',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/CalendarView')
    },
    {
        path: '/total-hours',
        name: 'totalHours',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/TotalHoursView')
    },

    {
        path: '/settings/:search?',
        name: 'settings',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/SettingsView')
    },
    {
        path: '/transactions/:search?',
        name: 'transactions',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/TransactionsView')
    },
    {
        path: '/holidays/:search?',
        name: 'holidays',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/HolidaysView')
    },
    {
        path: '/statistics',
        name: 'statistics',
        meta: {
            requiresAuth: true
        },
        component: () => import('../views/StatisticsView')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        useApiSettingStore().errorNetwork = false
        if (useAuthStore().isAuthenticated) {
            if (useAuthStore().userPermissionsGetters.electronic_badge_employee && useAuthStore().userPermissionsGetters.electronic_badge_employee.includes('view')) {
                useElectronicBadgeStore().getUserElectronicBadgeTrigger().then()
            }
            useNotificationStore().getUnread().then()
            next();
        } else {
            next('/login');
        }
    } else {
        next();
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.hideForAuth)) {
        if (useAuthStore().isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});


export default router
